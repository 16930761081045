import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './foundation.css'
import '../styles/app.scss'
import '../styles/index.scss'

const Base = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: '2022 Election: This election time, the GetUp movement is driving change for the better. Will you join us?' },
            { name: 'image', content: 'https://cdn.getup.org.au/image_17889_full.jpg' },
            { name: 'keywords', content: 'GetUp, election, 2022' },
            {
              name: 'viewport',
              content:
                'width=device-width, initial-scale=1.0, viewport-fit=cover'
            }
          ]}
        >
          <html lang='en' />
          <link
            href='https://fonts.googleapis.com/css?family=Inconsolata:400,700'
            rel='stylesheet'
          />
          <link
            href='https://fonts.googleapis.com/css?family=Roboto:300,400,700'
            rel='stylesheet'
          />
          <link
            href='https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,600'
            rel='stylesheet'
          />
        </Helmet>

        {children}
      </React.Fragment>
    )}
  />
)

export default Base
